import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(landing)": [~36,[11]],
		"/(landing)/about": [37,[12]],
		"/earth": [45],
		"/(landing)/jobs": [~38,[13]],
		"/(landing)/jobs/[id]": [~39,[13]],
		"/(auth)/login": [~33,[10]],
		"/(landing)/news": [~40,[14]],
		"/(landing)/news/[id]": [~41,[14]],
		"/(landing)/privacy": [42,[15]],
		"/(landing)/services": [43,[16]],
		"/(app)/settings/applications": [~18,[2,3,4]],
		"/(app)/settings/applications/create": [~20,[2,3,4]],
		"/(app)/settings/applications/[id]": [~19,[2,3,4]],
		"/(app)/settings/companies": [~21,[2,3,5]],
		"/(app)/settings/company": [~22,[2,3]],
		"/(app)/settings/jobs": [~23,[2,3,6]],
		"/(app)/settings/jobs/create": [~25,[2,3,6]],
		"/(app)/settings/jobs/[id]": [~24,[2,3,6]],
		"/(app)/settings/middlemans": [~26,[2,3,7]],
		"/(app)/settings/middlemans/create": [~27,[2,3,7]],
		"/(app)/settings/news": [~28,[2,3,8]],
		"/(app)/settings/news/create": [~30,[2,3,8]],
		"/(app)/settings/news/[id]": [~29,[2,3,8]],
		"/(app)/settings/profile": [~31,[2,3]],
		"/(app)/settings/users": [~32,[2,3,9]],
		"/(auth)/signup": [~34,[10]],
		"/(auth)/signup/company": [~35,[10]],
		"/(landing)/terms": [44,[17]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';